const aboutMe = {
    introduction: 
    "In the dynamic and ever-evolving realm of computer science, I stand as an enthusiastic MSci student, currently embarking on the third year of my university journey. My journey is marked by a steadfast passion for this field, with a particular focus on cybersecurity and web development – two areas that, in my view, are intrinsically interconnected and vital in the digital age.",
    skills:
    "My fascination with computing started early and has grown into a comprehensive academic and practical pursuit. Over the years, I have not only delved into the theoretical aspects of computer science but have also developed a robust skill set in web development. This proficiency in crafting and managing web applications has provided me with a unique perspective on cybersecurity.",
    synergy:
    "Web development is more than just creating aesthetically pleasing and functional websites; it is about building secure digital environments. My experience in web development has given me a practical understanding of how applications are structured, how data flows within these systems, and the importance of protecting these structures from cyber threats. This dual expertise in web development and cybersecurity is crucial, as it allows me to anticipate, identify, and mitigate potential vulnerabilities in web applications, ensuring their efficiency and security.",
    aspirations:
    "In my ongoing academic journey, I have prioritized gaining a well-rounded understanding of both the creative and protective aspects of computing. As I step into the third year of my MSCi program, I am more determined than ever to deepen my knowledge in cybersecurity, using my web development skills as a foundational asset. Looking ahead, I am actively seeking internship opportunities in cybersecurity and web development, where I can apply and expand my skills in real-world scenarios."
}

export default aboutMe;