const skillsData = [
    {
        label: "FRONT END",
        data: [
            {
                skillName: "HTML",
                percentage: "90"
            },
            {
                skillName: "CSS",
                percentage: "65"
            },
            {
                skillName: "JAVASCRIPT",
                percentage: "90"
            },
            {
                skillName: "REACT JS",
                percentage: "80"
            },
            {
                skillName: "Vue JS",
                percentage: "60"
            }
        ]
    },
    {
        label: "BACK END",
        data: [
            {
                skillName: "PYTHON",
                percentage: "95"
            },
            {
                skillName: "JAVA",
                percentage: "80"
            },
            {
                skillName: "EXPRESS",
                percentage: "85"
            },
            {
                skillName: "DJANGO",
                percentage: "80"
            },
        ]
    },
    {
        label: "DATABASES",
        data: [
            {
                skillName: "MYSQL",
                percentage: "90"
            },
            {
                skillName: "MONGODB",
                percentage: "70"
            },
            {
                skillName: "REDIS",
                percentage: "30"
            }
        ]
    },
];

export default skillsData;