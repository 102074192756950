import React from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';
import PageHeaderContent from '../../components/pageHeaderContent';
import { Animate } from 'react-simple-animate';
import './styles.scss';
import aboutMe from './utils.js';
import { DiApple } from 'react-icons/di';
import { FaDev, FaDatabase } from 'react-icons/fa';
import { SiKalilinux } from 'react-icons/si';

const personalDetails = [
  {
    label: "Name",
    value: "Manuel Berraquero Sanchez"
  },
  {
    label: "Age",
    value: "21"
  },
  {
    label: "Address",
    value: "United Kingdom"
  },
  {
    label: "Email",
    value: "manuelberrasan@gmail.com"
  },
  {
    label: "Contact No",
    value: "+44 7517632119"
  }
];

const About = () => {
  return (
    <section id="about" className='about'>
      <PageHeaderContent
        headerText='About Me'
        icon={<BsInfoCircleFill size={40} />}
      />
      <div className='about__content'>
        <div className='about__content__personalWrapper'>
          <Animate
            play
            duration={1}
            delay={0}
            start={{
              transform: 'translateX(-400px)'
            }}
            end={{
              transform: 'translateX(0px)'
            }}
          >
            <h3 className='about__content__personalWrapper__headerText'>Computer Science Student</h3>
            <p>{aboutMe.introduction}<br/><br/>{aboutMe.skills}<br/><br/>{aboutMe.synergy}<br/><br/>{aboutMe.aspirations}</p>
            <h3 className='about__content__personalWrapper__headerText' id='personalInformationHeaderText'>Personal Information</h3>
            <ul>
              {
                personalDetails.map((item, key) => (
                  <li key={key}>
                    <span className='title'>{item.label}</span>
                    <span className='value'>{item.value}</span>
                  </li>

                ))
              }
            </ul>
          </Animate>
        </div>
        <div className='about__content__servicesWrapper'>
        <Animate
            play
            duration={1}
            delay={0}
            start={{
              transform: 'translateX(400px)'
            }}
            end={{
              transform: 'translateX(0px)'
            }}
          >
            <div className='about__content__servicesWrapper__innerContent'>
              <div>
                <FaDev size={60} color="var(--yellow-theme-main-color)" />
              </div>
              <div>
                <SiKalilinux size={90} color="var(--yellow-theme-main-color)" />
              </div>
              <div>
                <FaDatabase size={60} color="var(--yellow-theme-main-color)" />
              </div>
              <div>
                <DiApple size={60} color="var(--yellow-theme-main-color)" />
              </div>
            </div>
          </Animate>
        </div>
      </div>
    </section>
  )
}

export default About;