import { Line } from 'rc-progress';
import React from 'react';
import { GiSkills } from 'react-icons/gi';
import { Animate } from 'react-simple-animate';
import PageHeaderContent from '../../components/pageHeaderContent';
import skillsData from './utils';
import './styles.scss';

const Skills = () => {
  return (
    <section id="skills" className='skills'>
      <PageHeaderContent
        headerText='My Skills'
        icon={<GiSkills size={40} />}
      />

      <div className='skills__contentWrapper'>
        {
          skillsData.map((item, key) => (
            <div key={key} className='skills__contentWrapper__innerContent'>
              <Animate
              play
              duration={1}
              delay={0}
              start={{
                transform: "translateX(-200px)"
              }}
              emd={{
                transform: "translateX(0px)"
              }}
              >
                <h3 className='skills__contentWrapper__innerContent__categoryText'>{item.label}</h3>
                <div className='skills__contentWrapper__innerContent__progressbarContainer'>
                  {
                    item.data.map((skill, skillKey) => (
                        <div className='progressbarWrapper' key={skillKey}>
                          <p>{skill.skillName}</p>
                          <Line
                          percent={skill.percentage}
                          strokeWidth="2"
                          strokeColor="var(--yellow-theme-main-color)"
                          trailWidth="2"
                          strokeLinecap="square"
                          className='progress'
                          />
                        </div>
                    ))
                  }
                </div>

              </Animate>
            </div>
          ))
        }
      </div>
    </section>
  )
}

export default Skills;