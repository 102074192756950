import './App.scss';
import { Routes, Route, useLocation } from 'react-router-dom';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import Home from './pages/home';
import About from './pages/about';
import Resume from './pages/resume';
import Skills from './pages/skills';
import Contact from './pages/contact';
import NavBar from './components/navBar';
import Projects from './pages/projects';
import particles from './utils/particles';

function App() {

  const location = useLocation();


  const handleInit = async (main) => {
    await loadFull(main);
  }

  const renderParticleJsInHomePage = location.pathname === "/";
  
  return (
    <div className="App">

      {/* particles js */}
      {renderParticleJsInHomePage && (
        <Particles id="particles" options={particles} init={handleInit}/>
      )}

      {/* navbar */}
      <NavBar/>

      {/* main page content */}

      <div className='App__main-page-content'>
      <Routes>
        <Route index path='/' element={<Home/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/resume' element={<Resume/>} />
        <Route path='/skills' element={<Skills/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/projects' element={<Projects/>} />
      </Routes>
      </div>
    
    </div>
  );
}

export default App;
