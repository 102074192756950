import React from 'react';
import { FaBlackTie } from 'react-icons/fa';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { Animate } from 'react-simple-animate';
import PageHeaderContent from '../../components/pageHeaderContent';
import 'react-vertical-timeline-component/style.min.css'
import data from './utils.js';
import './styles.scss';
//import { MdWork } from 'react-icons/md';
import { GiGraduateCap } from 'react-icons/gi';

const Resume = () => {

  return (
    <section id="resume" className='resume'>
      <PageHeaderContent
        headerText='My Resume'
        icon={<FaBlackTie size={40} />}
      />
      <Animate
        play
        duration={1}
        delay={0}
        start={{
          transform: "translateY(600px)"
        }}
        emd={{
          transform: "translateY(0px)"
        }}
      >
        <div className='timeline'>
          {/*
          <div className='timeline__experience'>
            <h3 className='timeline__experience__headerText'>
              Experience
            </h3>
            
              <VerticalTimeline
              layout={'1-column'}
              lineColor='var(--yellow-theme-main-color)'
              >
                {
                  data.experience.map((item, key) => (
                    <VerticalTimelineElement
                    key={key}
                    className='timeline__experience__verticalTimelineElement'
                    contentStyle={{
                      background: 'none',
                      color: 'var(--yellow-theme-sub-text-color)',
                      border: '1.5px solid var(--yellow-theme-main-color)'
                    }}
                    date={item.date}
                    icon={<MdWork/>}
                    iconStyle={{
                      background: '#181818',
                      color: 'var(--yellow-theme-main-color)'
                    }}
                    >
                      <div className='verticalTimelineElementTitleWrapper'>
                        <h3>
                          {item.title}
                        </h3>
                        <h4>
                          {item.subTitle}
                        </h4>
                      </div>
                      <p className='verticalTimelineElementTitleWrapperDescription'>{item.description}</p>

                    </VerticalTimelineElement>
                  ))
                }
              </VerticalTimeline>
          
          </div>
              */}
          <div className='timeline__education'>
            <h3 className='timeline__education__headerText'>
              Education
            </h3>
            
              <VerticalTimeline
              layout={'1-column'}
              lineColor='var(--yellow-theme-main-color)'
              >
                {
                    data.education.map((item, key) => (
                      <VerticalTimelineElement
                      key={key}
                      className='timeline__education__verticalTimelineElement'
                      contentStyle={{
                        background: 'none',
                        color: 'var(--yellow-theme-sub-text-color)',
                        border: '1.5px solid var(--yellow-theme-main-color)'
                      }}
                      date={item.date}
                      icon={<GiGraduateCap/>}
                      iconStyle={{
                        background: '#181818',
                        color: 'var(--yellow-theme-main-color)'
                      }}
                      >
                        <div className='verticalTimelineElementTitleWrapper'>
                          <h3>
                            {item.title}&nbsp;
                          </h3>
                          <h4>
                            {item.subTitle}
                          </h4>
                        </div>
                        <p className='verticalTimelineElementTitleWrapperDescription'>{item.description}</p>

                      </VerticalTimelineElement>
                    ))
                }
                
              </VerticalTimeline>
            
          </div>
        </div>
      </Animate>
    </section>
  )
}

export default Resume;