import React, { useState } from 'react';
import { MdAutoGraph } from 'react-icons/md';
import { Animate } from 'react-simple-animate';
import PageHeaderContent from '../../components/pageHeaderContent';
import ImageOne from '../../images/image1.png';
import ImageTwo from '../../images/image2.png';
import ImageThree from '../../images/image3.png';
import ImageFour from '../../images/image4.png';
import './styles.scss';

const projectsData = [
  {
    id: 3,
    name: "Todo App",
    image: ImageOne,
    link: 'https://main.d1rz5umfkn6qqv.amplifyapp.com',
    code: 'https://github.com/Mberra6/todolist-react'
  },
  {
    id: 3,
    name: "E-learning platform",
    image: ImageTwo,
    link: 'http://rsafrontend.s3-website.eu-west-2.amazonaws.com',
    code: 'https://github.com/Mberra6/Real-Support-Academy'
  },
  {
    id: 3,
    name: "Text Reverser App",
    image: ImageThree,
    link: 'https://master.d2liw1ey0h20u5.amplifyapp.com',
    code: 'https://github.com/Mberra6/CapitaliseAndReverse'
  },
  {
    id: 2,
    name: "Packet Sniffer",
    image: ImageFour,
    link: 'https://youtu.be/tdMjlxL4PHM',
    code: 'https://github.com/Mberra6/Packet-Sniffer'
  },
];

const filterData = [
  {
    filterId: 1,
    label: "All"
  },
  {
    filterId: 2,
    label: "Cybersecurity"
  },
  {
    filterId: 3,
    label: "Web Development"
  }
]

const Projects = () => {

  const [filteredValue, setFilteredValue] = useState(1);
  const [hoveredValue, setHoveredValue] = useState(null);

  const handleFilter = (currentId) => {
    setFilteredValue(currentId);
  };

  const handleHover = (index) => {
    setHoveredValue(index);
  }

  const handleClick = (link) => {
    return window.open(link, '_blank');
  }

  const filteredItems = filteredValue === 1 ? projectsData :
  projectsData.filter((item) => item.id === filteredValue);

  return (
    <section id="projects" className='projects'>
      <PageHeaderContent
        headerText='My Projects'
        icon={<MdAutoGraph size={40} />}
      />
      <Animate
            play
            duration={1.5}
            delay={0}
            start={{
              transform: 'translateX(-600px)'
            }}
            end={{
              transform: 'translateX(0px)'
            }}
          >
        <div className='projects__content'>
          <ul className='projects__content__filter'>
            {
              filterData.map((item) => (
                <li className={item.filterId === filteredValue ? 'active' : ''} onClick={()=>handleFilter(item.filterId)} key={item.filterId}>
                  {item.label}
                </li>
              ))
            }
          </ul>
          <div className='projects__content__cards'>
            {
              filteredItems.map((item, index) => (
                <div key={`cardItem${item.name.trim()}`} className='projects__content__cards__item' onMouseEnter={() => handleHover(index)} onMouseLeave={() => handleHover(null)}>
                  <div className='projects__content__cards__item__imgWrapper'>
                    <a>
                      <img alt="dummy" src={item.image}/>
                    </a>
                  </div>
                  <div className='overlay'>
                    {
                      index === hoveredValue && (
                        <div>
                          <p>{item.name}</p>
                          <button onClick={() => handleClick(item.link)}>Visit</button>
                          <button onClick={() => handleClick(item.code)}>Code</button>
                        </div>
                      )
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </Animate>
    </section>
  )
}

export default Projects;