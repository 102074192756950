import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Animate } from 'react-simple-animate';
import { FaGithub,  FaLinkedin, FaTwitter } from "react-icons/fa";
import CV from '../../utils/files/ManuelBerraqueroSanchezCV.pdf'
import './styles.scss';

const Home = () => {

  const navigate = useNavigate();

  const handleNavigateToContactMePage = () => {
    navigate('/contact');

  }

  return (
    <section id="home" className='home'>
      <div className='home__text-wrapper'>
        <h1>
          Hello, I'm Manuel
          <br/>
          Computer Science Student
        </h1>
      </div>
      <Animate 
      play 
      duration={1.5} 
      delay={1}
      start={{
        transform: 'translateY(550px)'
      }}
      end={{
        transform: 'translateX(0px)'
      }}
      >
        <div className='home__contact-me'>
          <button onClick={handleNavigateToContactMePage}>Contact Me</button>
          <a href={CV} download="ManuelBerraqueroSanchezCV" target='_blank' rel="noreferrer">
            <button className='cvButton'>Download CV</button>
          </a>
        </div>
        <div className='home__logos'>
          <a href='https://www.linkedin.com/in/manuel-bs/' target='_blank' rel='noopener' aria-label='Github'>
            <FaLinkedin size={30}/>
          </a>
          <a href='https://github.com/Mberra6' target='_blank' rel='noopener' aria-label='Github'>
            <FaGithub size={30}/>
          </a>
          <a href='https://twitter.com/Mberras6' target='_blank' rel='noopener' aria-label='Github'>
            <FaTwitter size={30}/>
          </a>
        </div>
      </Animate>
    </section>
  )
}

export default Home;