import React, { useState } from 'react';
import { RiContactsFill } from 'react-icons/ri';
import { Animate } from 'react-simple-animate';
import emailjs from '@emailjs/browser';
import PageHeaderContent from '../../components/pageHeaderContent';
import './styles.scss'

const Contact = () => {

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [description, setDescription] = useState();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_kipttn7', 'template_ncqdjth', e.target, 'G8fAc4L-hkPHmUR6m')
    .then(function(response) {
      console.log('SUCCESS!', response.status, response.text);
      window.alert("SUCCESSFUL - Message sent!");
    }, function(error) {
      console.log('FAILED...', error);
      window.alert("ERROR - Message NOT sent!");
    });
    setName('');
    setEmail('');
    setDescription('');
  }

  return (
    <section id="contact" className='contact'>
      <PageHeaderContent
        headerText='Contact Me'
        icon={<RiContactsFill size={40} />}
      />
      <div className='contact__content'>
        <Animate
          play
          duration={1}
          delay={0}
          start={{
            transform: "translateX(-200px)"
          }}
          end={{
            transform: "translateX(0px)"
          }}
        >
          <h3 className='contact__content__headerText'>Let's Talk</h3>
        </Animate>
        <Animate
          play
          duration={1}
          delay={0}
          start={{
            transform: "translateX(200px)"
          }}
          end={{
            transform: "translateX(0px)"
          }}
        >
          <form onSubmit={sendEmail} className='contact__content__form'>
            <div className='contact__content__form__controlsWrapper'>
              <div>
                <input required name="name" className='inputName' type={'text'} value={name} onChange={(e) => setName(e.target.value)} />
                <label htmlFor='name' className='nameLabel'>Name</label>
              </div>
              <div>
                <input required name="from_email" className='inputEmail' type={'text'} value={email} onChange={(e) => setEmail(e.target.value)} />
                <label htmlFor='from_email' className='emailLabel'>Email</label>
              </div>
              <div>
                <textarea required name="description" className='inputDescription' type={'text'} rows="5" value={description} onChange={(e) => setDescription(e.target.value)}/>
                <label htmlFor='description' className='descriptionLabel'>Description</label>
              </div>
            </div>
            <button>Submit</button>
          </form>
        </Animate>

      </div>
    </section>
  )
}

export default Contact;