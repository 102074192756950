const data = {
    experience: [
        {
            title: "Consultant",
            subTitle: "London, UK",
            description: 
                "User Experience, Visual Design",
                date: "2020 - 2021"
        },
        {
            title: "Senior Consultant",
            subTitle: "London, UK",
            description: 
                "Web Development, Databases, Backend Development",
            date: "2021 - 2022"
        },
        {
            title: "Project Manager",
            subTitle: "London, UK",
            description: 
                "Creative Direction Project Management",
            date: "2022 - 2023"
        },
    ],
    education: [
        {
            title: "Secondary",
            subTitle: "Cadiz, Spain",
            description: 
                "Up to Year 11",
            date: "2005 - 2018"
        },
        {
            title: "A-Levels",
            subTitle: "Greshams School, UK",
            description: 
                "Computer Science: A*, Spanish: A*, Maths: B, Physics: B",
            date: "2018 - 2020"
        },
        {
            title: "MSci Computer Science",
            subTitle: "Queen Mary University of London",
            description: 
                "Predicted First Class",
                date: "2020 - Present"
        },
    ]
};

export default data;